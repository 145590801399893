
const Ironitemsdata = [
    {
        dimg:"../itemimages/ironitems/tin.jpg",
        dname:"tin",
        dprice:"8",
        dunit:"per/kg",
        ddese:"depend on items"
    },
    {
        dimg:"../itemimages/ironitems/loha.jpg",
        dname:"loha",
        dprice:"28",
        dunit:"per/kg",
        ddese:"depend on items"
    },
    
];


const heading = "iron items";

export default Ironitemsdata;

export {heading};