
const Glassitemsdata = [
    {
        dimg:"../itemimages/glassitems/bottle.jpg",
        dname:"bottle",
        dprice:"2",
        dunit:"per/pcs",
        ddese:"depend on items"
    },
    
    
];


const heading = "Glass items";

export default Glassitemsdata;

export {heading};