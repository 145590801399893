
const Vehicleitemsdata = [
    {
        dimg:"../itemimages/vehicleitems/bike.jpg",
        dname:"bike",
        dprice:"4000-7000",
        dunit:"per/pcs",
        ddese:"depend on items"
    },
    {
        dimg:"../itemimages/vehicleitems/scooty.jpg",
        dname:"scooty",
        dprice:"3000-4000",
        dunit:"per/pcs",
        ddese:"depend on items"
    },
    
    
];


const heading = "Vehicle items";

export default Vehicleitemsdata;

export {heading};