import './Whatsapp.css';

const Whatsapp =()=>{
    return(
        <>
        <div id="whatsappIcon">
            <a href="https://wa.me/919911223199"  class="wtsapp">
                <i class="fa-brands fa-whatsapp"></i>
            </a>
        </div>
        </>
    );
};

export default Whatsapp;