
const Paperitemsdata = [
    {
        dimg:"../itemimages/paperitems/newspaper.jpg",
        dname:"newspaper",
        dprice:"14",
        dunit:"per/kg",
        ddese:"depend on items"
    },
    {
        dimg:"../itemimages/paperitems/gatta.jpg",
        dname:"gatta",
        dprice:"8",
        dunit:"per/kg",
        ddese:"depend on items"
    },
    {
        dimg:"../itemimages/paperitems/magzine.jpg",
        dname:"magzine",
        dprice:"12",
        dunit:"per/kg",
        ddese:"depend on items"
    },
    
    
    
];


const heading = "Paper items";

export default Paperitemsdata;

export {heading};