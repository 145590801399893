
const Plasticitemsdata = [
    {
        dimg:"../itemimages/plasticitems/mixplastic.jpg",
        dname:"mix plastic",
        dprice:"10",
        dunit:"per/kg",
        ddese:"depend on items"
    },
    
    
];


const heading = "plastic items";

export default Plasticitemsdata;

export {heading};