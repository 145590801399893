
const Wooditemsdata = [
    {
        dimg:"../itemimages/wooditems/gate.jpg",
        dname:"gate",
        dprice:"depend on condition",
        dunit:"",
        ddese:""
    },
    {
        dimg:"../itemimages/wooditems/window.jpg",
        dname:"window",
        dprice:"depend on condition",
        dunit:"",
        ddese:""
    },
     
];


const heading = "Wood items";

export default Wooditemsdata;

export {heading};